import { useRef } from "react";
import styled from "styled-components";
import theme from "theme";
import { Button, ButtonProps, useMediaQuery } from "@mui/material";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetDicerollCount } from "hooks/useGetCount";
import CounterList from "./Counter";
import Slider from "react-slick";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const MainVisual = () => {
  const [t, i18n] = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const container = useRef<HTMLDivElement | null>(null);

  const { data, loading, error } = useGetDicerollCount();

  const sliderResponsiveOption = {
    responsive: [
      {
        breakpoint: 9999999,
        settings: "unslick" as "unslick",
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: "60px",
          dots: false,
          arrows: false,
          autoplay: true,
          variableWidth: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  useGSAP(
    () => {
      if (container.current) {
        const tl = gsap.timeline({});
        tl.from(".anim-title", { delay: 0.2, opacity: 0, duration: 0.1 })
          .from(".anim-button", {
            opacity: 0,
            y: 10,
            ease: "power3.out",
          })
          .from(".anim-counter", { opacity: 0, y: 5 }, "=-0.4");
      }
    },
    { scope: container }
  );

  return (
    <MainVisualWrap ref={container}>
      <MainVisualContainer>
        <MainVisualTitle className="anim-title">
          <Trans>
            ココフォリアで
            <br style={{ display: matches ? "none" : "block" }} />
            遠くはなれた友達と
            <br />
            オンラインセッションを
            <br style={{ display: matches ? "none" : "block" }} />
            楽しもう
          </Trans>
        </MainVisualTitle>
        <Counter className="anim-counter">
          <h3>{t("本日のココフォリア")}</h3>
          <CounterItems>
            <Slider
              speed={1000}
              autoplaySpeed={5000}
              responsive={sliderResponsiveOption.responsive}
            >
              <CounterItemWrap className="anim-counter-items">
                <CounterItem>
                  <h4 className="counter__title">
                    <div className="title--icon">
                      <img
                        src="/images/top/user.svg"
                        alt="ユーザーのアイコン"
                      />
                    </div>
                    USER
                  </h4>
                  <div className="counter__values">
                    <div className="counter__values--main">
                      {data?.userCount.all ? (
                        <CounterList value={data?.userCount.all} />
                      ) : (
                        <CounterInit>0</CounterInit>
                      )}
                    </div>
                  </div>

                  <div className="counter__values--sub">
                    <div className="counter__sub">
                      <div className="counter__sub--title">
                        {" "}
                        {t("ログイン")}
                      </div>
                      <div className="counter__sub--value">
                        {data?.userCount.login
                          ? data.userCount.login.toLocaleString()
                          : "0"}
                      </div>
                    </div>
                    <div className="counter__sub">
                      <div className="counter__sub--title"> {t("ゲスト")}</div>
                      <div className="counter__sub--value">
                        {data?.userCount.guest
                          ? data.userCount.guest.toLocaleString()
                          : "0"}
                      </div>
                    </div>
                  </div>
                </CounterItem>
              </CounterItemWrap>
              <CounterItemWrap>
                <CounterItem>
                  <h4 className="counter__title">
                    <div className="title--icon">
                      <img src="/images/top/dice.svg" alt="ダイスのアイコン" />
                    </div>
                    DICEROLL
                  </h4>
                  <div className="counter__values">
                    <div className="counter__values--main">
                      {data?.diceRoll.all ? (
                        <CounterList value={data?.diceRoll.all} />
                      ) : (
                        <CounterInit>0</CounterInit>
                      )}
                    </div>
                  </div>

                  <div className="counter__values--sub">
                    <div className="counter__sub">
                      <div className="counter__sub--title u-color--blue">
                        {t("成功")}
                      </div>
                      <div className="counter__sub--value">
                        {data ? data.diceRoll.success.toLocaleString() : "0"}
                      </div>
                    </div>
                    <div className="counter__sub">
                      <div className="counter__sub--title u-color--red">
                        {t("失敗")}
                      </div>
                      <div className="counter__sub--value">
                        {data ? data.diceRoll.failure.toLocaleString() : "0"}
                      </div>
                    </div>
                  </div>
                </CounterItem>
              </CounterItemWrap>
              <CounterItemWrap>
                <CounterItem>
                  <h4 className="counter__title">
                    <div className="title--icon">
                      <img src="/images/top/room.svg" alt="ルームのアイコン" />
                    </div>
                    ROOM
                  </h4>
                  <div className="counter__values">
                    <div className="counter__values--main">
                      {data?.roomCount.all ? (
                        <CounterList value={data?.roomCount.all} />
                      ) : (
                        <CounterInit>0</CounterInit>
                      )}
                    </div>
                  </div>

                  <div className="counter__values--sub">
                    <div className="counter__sub">
                      <div className="counter__sub--title">
                        {" "}
                        {t("新しいルーム")}
                      </div>
                      <div className="counter__sub--value">
                        {data?.roomCount.new
                          ? data?.roomCount.new.toLocaleString()
                          : "0"}
                      </div>
                    </div>
                  </div>
                </CounterItem>
              </CounterItemWrap>
            </Slider>
          </CounterItems>
        </Counter>
        <MainVisualButton
          size="large"
          variant="contained"
          fullWidth
          component={Link}
          to="/home"
          color="secondary"
          className="anim-button"
        >
          {t("今すぐはじめる")}
        </MainVisualButton>
      </MainVisualContainer>
      <MainVisualImage>
        {i18n.language === "ja" &&
          (matches ? (
            <img
              src="/images/top/mv/Japanese-PC.png"
              alt="ココフォリアの画像"
            />
          ) : (
            <img
              src="/images/top/mv/Japanese-SP.png"
              alt="ココフォリアの画像"
            />
          ))}
        {i18n.language === "en" &&
          (matches ? (
            <img src="/images/top/mv/English-PC.png" alt="CCFOLIA's Image" />
          ) : (
            <img src="/images/top/mv/English-SP.png" alt="CCFOLIA's Image" />
          ))}
        {i18n.language === "ko" &&
          (matches ? (
            <img src="/images/top/mv/Korean-PC.png" alt="CCFOLIA's Image" />
          ) : (
            <img src="/images/top/mv/Korean-SP.png" alt="CCFOLIA's Image" />
          ))}
        {i18n.language === "zhTw" &&
          (matches ? (
            <img src="/images/top/mv/Chinese-PC.png" alt="CCFOLIA's Image" />
          ) : (
            <img src="/images/top/mv/Chinese-SP.png" alt="CCFOLIA's Image" />
          ))}
      </MainVisualImage>
    </MainVisualWrap>
  );
};

const MainVisualWrap = styled.div``;

const MainVisualContainer = styled.section`
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-rows: repeat(3, 0fr);
  gap: 40px 0;
  width: 100%;
  padding-bottom: 160px;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  background-image: linear-gradient(0deg, transparent 47px, #acacac 48px),
    linear-gradient(90deg, transparent 47px, #acacac 48px);
  background-size: 48px 48px;
  > * {
    position: relative;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #202020 30px, transparent 200px),
      radial-gradient(rgba(64, 64, 64, 0.6), rgba(32, 32, 32, 1) 80%);
    z-index: 0;
  }

  ${theme.breakpoints.down("md")} {
  }
  ${theme.breakpoints.down("sm")} {
    grid-template-columns: 100%;
    padding-bottom: 112px;
    gap: 0;
  }
`;

const MainVisualTitle = styled.h2`
  margin: 160px auto 0;
  font-size: 40px;
  text-align: center;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    margin: 96px auto 0;
    font-size: 24px;
  }
`;

const Counter = styled.div`
  h3 {
    font-size: 16px;
    text-align: center;
    color: #acacac;
    ${theme.breakpoints.down("md")} {
      font-size: 14px;
      margin: 24px 0 16px;
    }
  }
  ${theme.breakpoints.up("sm")} {
    .regular.slider {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0 24px;
      margin: 16px auto 0;
    }
  }
`;

const CounterItems = styled.div`
  max-width: 651px;
  margin: 0 auto;
  ${theme.breakpoints.down("md")} {
    margin: 16px auto 0;
    .slick-list {
      padding-bottom: 26px !important;
    }
  }
`;

const CounterItemWrap = styled.div``;

const CounterItem = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 58px 33px;
  grid-template-areas: "title" "main-value" "sub-value";
  padding: 24px 24px 30px;
  min-width: 201px;
  box-sizing: border-box;
  background: rgba(32, 32, 32, 0.87);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.7);

  ${theme.breakpoints.down("md")} {
    min-width: 200px;
  }
  ${theme.breakpoints.down("sm")} {
    margin: 0 8px;
  }

  .counter__title {
    display: grid;
    place-items: center;
    grid-area: title;
    padding-bottom: 14px;
    color: #fff;
    font-size: 20px;
    font-family: "Anton", sans-serif;
    letter-spacing: 1.3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .title--icon {
    min-height: 24px;
  }
  .counter__values {
    grid-area: main-value;
    margin-top: 17px;
    font-size: 48px;
    text-align: center;
    font-family: "Bebas Neue", "Noto Sans JP", sans-serif;
    color: #fff;
    font-weight: 400;
  }

  .counter__values--main {
    display: flex;
    justify-content: center;
  }

  .counter__values--sub {
    grid-area: sub-value;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    gap: 0 16px;
  }
  .counter__sub {
    .counter__sub--title {
      color: #606060;
      font-size: 10px;
      text-align: center;
      font-weight: 700;
    }
    .counter__sub--value {
      margin-top: 1px;
      font-size: 16px;
      text-align: center;
      font-family: "Bebas Neue", "Noto Sans JP", sans-serif;
      color: #fff;
    }
    .u-color--red {
      color: rgba(244, 0, 87, 1);
    }
    .u-color--blue {
      color: rgba(33, 150, 243, 1);
    }
  }
`;

const CounterInit = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 36px;
  margin: 3px 0 0;
  line-height: 1;
  list-style: none;
  box-sizing: border-box;
`;

const MainVisualButton = styled(Button)<ButtonProps<"a">>`
  max-width: 400px;
  height: 56px;
  margin: 0 auto;
  font-size: 18px;
  ${theme.breakpoints.down("sm")} {
    max-width: 240px;
    height: 48px;
    font-size: 16px;
  }
`;

const MainVisualImage = styled.div`
  position: relative;
  z-index: 2;
  width: calc(1152 / 1400 * 100%);
  max-width: 1152px;
  margin: -80px auto 0;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 1);
  border-radius: 16px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
  ${theme.breakpoints.down("sm")} {
    margin: -56px auto 0;
    box-shadow: 0px 0px 20px 0px #000;
  }
`;

export default MainVisual;
