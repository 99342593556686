import { Typography, TypographyProps } from "@mui/material";
import styled from "styled-components";
import theme from "theme";

type Props = {
  img: string;
  title: string;
  text: string;
  lang: string;
};

const AboutCard = (props: Props) => {
  return (
    <>
      <Card>
        <CardImage>
          <img src={props.img} alt="ココフォリアでできることのイラスト" />
        </CardImage>
        <CardTitle color="textPrimary">{props.title}</CardTitle>
        <CardText lang={props.lang} color="textSecondary">
          {props.text}
        </CardText>
      </Card>
    </>
  );
};

const Card = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 0fr);
  padding: 32px 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  background: rgba(42, 42, 42, 1);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
  font-family: "Noto Sans JP", sans-serif;
  ${theme.breakpoints.down("sm")} {
    padding: 16px 12px;
  }
`;
const CardImage = styled.div`
  aspect-ratio: 111 / 80;
  img {
    width: 100%;
  }
`;
const CardTitle = styled(Typography).attrs<TypographyProps<"h4">>({
  component: "h4",
})`
  margin-top: 24px;
  margin-inline: auto;
  font-size: 20px;
  font-weight: 700;
  inline-size: fit-content;
  ${theme.breakpoints.down("sm")} {
    margin-top: 8px;
    font-size: 14px;
  }
`;
const CardText = styled(Typography)<Props>`
  margin-top: 16px;
  text-align: ${(props) => (props.lang === "ja" ? "justify" : "none")};
  font-feature-settings: "halt" on;
  font-size: 14px;
  line-height: 1.6;
  word-wrap: break-word;
  ${theme.breakpoints.down("sm")} {
    margin-top: 8px;
    font-size: 12px;
  }
`;

export default AboutCard;
