import { memo, RefObject, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { Dialog } from "@mui/material";
import { DialogActions, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TimerRef } from "containers/Timer";
import { useForm } from "react-hook-form";

type TimerSettingDialogProps = {
  timerRef: RefObject<TimerRef>;
};

type FormData = {
  min: number;
  sec: number;
};

const TimerSettingDialog = ({ timerRef }: TimerSettingDialogProps) => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomTimerSetting")
  );

  const { register, handleSubmit } = useForm({
    defaultValues: { min: 0, sec: 0 },
  });

  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomTimerSetting = false;
      })
    );
  }, [d]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (!Number.isNaN(values.min) || !Number.isNaN(values.sec)) {
        const min = values.min || 0;
        const sec = values.sec || 0;
        const duration = Math.trunc(min) * 60 + Math.trunc(sec);
        d(
          store.updateCurrentRoom({
            timer: {
              duration,
              startTime: 0,
              current: 0,
            },
          })
        );
        if (timerRef.current) {
          timerRef.current.start(duration * 1000);
        }
      }
      onClose();
    },
    [timerRef, onClose, d]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FieldGroup>
          <TextField
            variant="standard"
            label={t("分")}
            type="number"
            {...register("min", { valueAsNumber: true })}
          />
          <TextField
            variant="standard"
            label={t("秒")}
            type="number"
            {...register("sec", { valueAsNumber: true })}
          />
        </FieldGroup>
        <DialogActions style={{ background: "rgba(0, 0, 0, 0.24)" }}>
          <Button
            fullWidth
            color="primary"
            onClick={() => handleSubmit(onSubmit)()}
          >
            {t("START")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const FieldGroup = styled.div`
  padding: 16px;
  display: flex;
  > * + * {
    margin-left: 16px;
  }
`;

export default memo(TimerSettingDialog);
