import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  List,
  ListItemText,
  DialogActions,
  Button,
  ListItemButton,
  ListItem,
  ListItemSecondaryAction,
  Switch,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import { updateCurrentRoomField } from "stores/modules/entities.rooms/operations";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import styled from "styled-components";
import { clamp } from "modules/clamp";

type FormValues = {
  fieldWidth: number;
  fieldHeight: number;
  isFieldCover: boolean;
  displayGrid: boolean;
  gridSize: number;
};

const FIELD_SIZE_MIN = 0;
const FIELD_SIZE_MAX = 1000;

const FieldDetail = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) =>
    getAppState(state, "openRoomFieldEdit")
  );

  const fieldWidth = useAppSelector((state) => {
    return getCurrentRoom(state)?.fieldWidth || 0;
  });

  const fieldHeight = useAppSelector((state) => {
    return getCurrentRoom(state)?.fieldHeight || 0;
  });

  const isFieldCover = useAppSelector((state) => {
    return getCurrentRoom(state)?.fieldObjectFit === "cover";
  });

  const displayGrid = useAppSelector((state) => {
    return getCurrentRoom(state)?.displayGrid ?? false;
  });

  const gridSize = useAppSelector((state) => {
    return getCurrentRoom(state)?.gridSize ?? 1;
  });

  const { register, handleSubmit, reset, watch, control } = useForm<FormValues>(
    {
      defaultValues: {
        fieldWidth,
        fieldHeight,
        isFieldCover,
        displayGrid,
        gridSize,
      },
    }
  );

  useEffect(() => {
    if (open) {
      reset({
        fieldWidth,
        fieldHeight,
        isFieldCover,
        displayGrid,
        gridSize,
      });
    }
  }, [
    fieldWidth,
    fieldHeight,
    isFieldCover,
    displayGrid,
    gridSize,
    reset,
    open,
  ]);

  const disableGridSize = !watch("displayGrid");

  const onSubmit = useCallback(
    ({
      fieldWidth,
      fieldHeight,
      isFieldCover,
      displayGrid,
      gridSize,
    }: FormValues) => {
      dispatch(
        updateCurrentRoomField({
          fieldWidth: clamp(fieldWidth, {
            min: FIELD_SIZE_MIN,
            max: FIELD_SIZE_MAX,
          }),
          fieldHeight: clamp(fieldHeight, {
            min: FIELD_SIZE_MIN,
            max: FIELD_SIZE_MAX,
          }),
          displayGrid,
          gridSize: clamp(gridSize, { min: 1, max: FIELD_SIZE_MAX }),
          fieldObjectFit: isFieldCover ? "cover" : "fill",
        })
      );
      dispatch(appStateMutate((state) => (state.openRoomFieldEdit = false)));
    },
    [dispatch]
  );

  const onSelectBackground = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "background";
        state.openRoomImageSelectTarget = "background/set";
      })
    );
  }, [dispatch]);

  const onSelectForeground = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "foreground";
        state.openRoomImageSelectTarget = "foreground/set";
      })
    );
  }, [dispatch]);

  const onClose = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <AppBar position="sticky" elevation={0} color="default">
        <Toolbar variant="dense">
          <Typography variant="subtitle2">{t("フィールド設定")}</Typography>
          <StyledSpacer />
          <IconButton onClick={onClose} edge="end" size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ paddingBottom: "0" }}>
        <StyledForm>
          <TextField
            label={t("横幅")}
            variant="filled"
            type="number"
            margin="dense"
            fullWidth
            inputProps={{ min: "0", max: "1000" }}
            {...register("fieldWidth", { valueAsNumber: true })}
          />
          <TextField
            label={t("縦幅")}
            variant="filled"
            type="number"
            margin="dense"
            fullWidth
            inputProps={{ min: "0", max: "1000" }}
            {...register("fieldHeight", { valueAsNumber: true })}
          />
        </StyledForm>
        <List>
          <ListItem>
            <ListItemText
              style={{ paddingRight: "32px" }}
              primary={t("画像を自動トリミングする")}
              secondary={t("画像の縦横比を変えずに表示します")}
            />
            <ListItemSecondaryAction>
              <Controller
                name="isFieldCover"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Switch
                    onChange={onChange}
                    checked={value}
                    name={name}
                    inputRef={ref}
                  />
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              style={{ paddingRight: "32px" }}
              primary={t("前景にグリッドを表示する")}
              secondary={t("前景画像の上にマス目を表示します")}
            />
            <ListItemSecondaryAction>
              <Controller
                name="displayGrid"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Switch
                    onChange={onChange}
                    checked={value}
                    name={name}
                    inputRef={ref}
                  />
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <TextField
              label={t("グリッドサイズ")}
              variant="filled"
              type="number"
              margin="dense"
              fullWidth
              inputProps={{ min: "0", max: "1000" }}
              disabled={disableGridSize}
              {...register("gridSize", { valueAsNumber: true })}
            />
          </ListItem>
        </List>
        <Divider style={{ marginTop: "16px" }} />
        <List>
          <ListItemButton dense onClick={onSelectBackground}>
            <ListItemText
              primary={t("背景選択")}
              secondary={t("画面全体とパネルに表示される画像を設定します")}
            />
          </ListItemButton>
          <ListItemButton dense onClick={onSelectForeground}>
            <ListItemText
              primary={t("前景選択")}
              secondary={t("パネルのみ表示される画像を設定します")}
            />
          </ListItemButton>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} fullWidth color="primary">
          {t("適用")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledForm = styled.div`
  display: flex;
  .MuiTextField-root + .MuiTextField-root {
    margin-left: 16px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default memo(FieldDetail);
