import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { getRoomOrderdNoteIds } from "./selectors";
import { Note, NoteRecord, UpdateNote } from "./";
import { DefaultRootState, DefaultThunk } from "stores";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { addUndo } from "../entities.room.histories/slice";
import {
  calcOrderAppendingToTail,
  reorderEntities,
  undoRedoReorderEntities,
} from "../firestoreModuleUtils/ordaring";

export const notesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "notes");

export const subscribeRoomNotes = createSubscribeCollection(
  actions,
  (roomId: string) => query(notesRef(roomId), orderBy("order"))
);

export const addRoomNote =
  (roomId: string, uid: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const ids = getRoomOrderdNoteIds(state);
    const note: Note = {
      name: "",
      text: "",
      iconUrl: "",
      owner: uid,
      order: calcOrderAppendingToTail(ids, state.entities.roomNotes.entities),
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    const doc = await addDoc(notesRef(roomId), note);
    dispatch(
      addUndo({
        kind: "update-note",
        id: doc.id,
        before: null,
        after: note,
      })
    );
    return;
  };

export const updateRoomNote =
  (roomId: string, noteId: string, item: UpdateNote) => () => {
    return setDoc(
      doc(notesRef(roomId), noteId),
      {
        ...item,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const deleteRoomNote = (roomId: string, noteId: string) => () => {
  return deleteDoc(doc(notesRef(roomId), noteId));
};

export const reorderNotes = reorderEntities({
  selectOrderdIds: getRoomOrderdNoteIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomNotes.entities,
  actionReorder: actions.reorder,
  actionUpdateOrder: actions.updateOrder,
  collectionRef: notesRef,
  type: "note",
});

export const undoRedoReorderNotes = undoRedoReorderEntities({
  selectOrderdIds: getRoomOrderdNoteIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomNotes.entities,
  actionReorder: actions.reorder,
  actionUpdateOrder: actions.updateOrder,
  collectionRef: notesRef,
});

export const importRoomNotes =
  (notesDate: { [noteId: string]: UpdateNote }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const noteIds = Object.keys(notesDate);
    const batch = writeBatch(db);
    const ref = notesRef(roomId);
    noteIds.forEach((noteId) => {
      batch.set(doc(ref, noteId), NoteRecord(notesDate[noteId]));
    });
    return batch.commit();
  };
