import { memo } from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import { UserFile } from "stores/modules/entities.user.files";
import FileList from "../FileList";

type FileListContainerProps = {
  onSelect: (file: string) => void;
  group: "room" | "all";
  dir: UserFile["dir"] | null;
};

const FileListContainer = ({
  onSelect,
  group,
  dir,
}: FileListContainerProps) => {
  if (!dir) {
    return null;
  }

  switch (group) {
    case "room":
      return <FileListRoom onSelect={onSelect} dir={dir} />;
    case "all":
      return <FileListAll onSelect={onSelect} dir={dir} />;
    default:
      return null;
  }
};

type FileListBaseProps = {
  onSelect: (file: string) => void;
  dir: UserFile["dir"];
};

const FileListRoom = ({ onSelect, dir }: FileListBaseProps) => {
  const userFileIds = useAppSelector((state) =>
    store.getRoomUserFileIds(state, dir)
  );

  return <FileList userFileIds={userFileIds} onSelect={onSelect} />;
};

const FileListAll = ({ onSelect, dir }: FileListBaseProps) => {
  const userFileIds = useAppSelector((state) =>
    store.getSortedUserFileIdsByDir(state, dir)
  );

  return <FileList userFileIds={userFileIds} onSelect={onSelect} />;
};

export default memo(FileListContainer);
