import { memo, useCallback, useState } from "react";
import { useAppDispatch } from "stores";
import store from "stores/interfaces";
import styled from "styled-components";
import {
  TextField,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Avatar,
} from "@mui/material";
import FileListDialog from "../FileListDialog";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";

import useDialog from "hooks/dialog";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { Character } from "stores/modules/entities.room.characters";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
// import { getDiceSkin } from "modules/diceSkin";
// import { DiceSkinSettings } from "stores/modules/entities.user.setting";
// import DiceSkinSelectDialog from "containers/DiceSkinSelect/DiceSkinSelectDialog";
// import { ReactComponent as CcfoliaProIcon } from "containers/svg/CcfoliaPro.svg";
// import theme from "theme";
// import { getIsPro } from "stores/modules/app.user/selectors";

type FormValue = Omit<Character, "active">;

type CharacterFormProps = {
  onSubmit: (value: FormValue) => void;
};

const CharacterForm = ({ onSubmit }: CharacterFormProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  // const isPro = useAppSelector(getIsPro);

  const { register, setValue, watch, handleSubmit, control } =
    useFormContext<FormValue>();
  const iconUrl = watch("iconUrl");

  const {
    fields: faceFields,
    append: appendFace,
    remove: removeFace,
    update: updateFace,
  } = useFieldArray({
    control,
    name: "faces",
  });

  const {
    fields: statusFields,
    append: appendStatus,
    remove: removeStatus,
  } = useFieldArray({
    control,
    name: "status",
  });

  const {
    fields: paramFields,
    append: appendParam,
    remove: removeParam,
  } = useFieldArray({
    control,
    name: "params",
  });

  const dialog = useDialog();
  const dialog2 = useDialog();
  const onOpenDialog = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelectDir = "characters";
      })
    );
    dialog.open();
  }, [dispatch, dialog]);
  const onOpenDialog2 = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelectDir = "characters";
      })
    );
    dialog2.open();
  }, [dispatch, dialog2]);
  const onSelectImage = useCallback(
    (url: string) => {
      setValue("iconUrl", url, { shouldDirty: true });
    },
    [setValue]
  );
  const [faceIndex, setFaceIndex] = useState(-1);

  // const diceSkin = useMemo(
  //   () => getDiceSkin(values.diceSkin.d4),
  //   [values.diceSkin.d4]
  // );
  // const [openDiceSkinSelect, setOpenDiceSkinSelect] = useState(false);
  // const onOpenDiceSkinSelect = useCallback(() => {
  //   setOpenDiceSkinSelect(true);
  // }, [setOpenDiceSkinSelect]);
  // const onCloseDiceSkinSelect = useCallback(() => {
  //   setOpenDiceSkinSelect(false);
  // }, [setOpenDiceSkinSelect]);
  // const onSelectDiceSkin = useCallback(
  //   (diceSkinId: string) => {
  //     const diceSkin: DiceSkinSettings = {
  //       d4: diceSkinId,
  //       d6: diceSkinId,
  //       d8: diceSkinId,
  //       d10: diceSkinId,
  //       d12: diceSkinId,
  //       d20: diceSkinId,
  //       d100: diceSkinId,
  //     };
  //     setFieldValue("diceSkin", diceSkin);
  //   },
  //   [setFieldValue]
  // );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledGroup>
            <IconButton size="small" onClick={onOpenDialog}>
              <Avatar src={toCDNUrl(iconUrl)} />
            </IconButton>
            <TextField
              variant="standard"
              label={t("名前")}
              margin="dense"
              fullWidth
              inputProps={register("name")}
            />
            <TextField
              variant="standard"
              label={t("イニシアティブ")}
              margin="dense"
              type="number"
              fullWidth
              inputProps={register("initiative", { valueAsNumber: true })}
            />
          </StyledGroup>
          <TextField
            label={t("キャラクターメモ")}
            variant="filled"
            margin="dense"
            fullWidth
            multiline
            rows={4}
            inputProps={register("memo")}
          />
          <StyledGroup>
            <TextField
              variant="standard"
              label={t("駒サイズ")}
              margin="dense"
              type="number"
              fullWidth
              inputProps={{
                min: "1",
                max: "100",
                ...register("width", { valueAsNumber: true }),
              }}
            />
            <TextField
              variant="standard"
              label={t("x")}
              margin="dense"
              type="number"
              fullWidth
              inputProps={register("x", { valueAsNumber: true })}
            />
            <TextField
              variant="standard"
              label={t("y")}
              margin="dense"
              type="number"
              fullWidth
              inputProps={register("y", { valueAsNumber: true })}
            />
          </StyledGroup>
          <TextField
            variant="standard"
            label={t("参照URL")}
            margin="dense"
            fullWidth
            placeholder={t("キャラクターシートなどの参照URL")}
            inputProps={register("externalUrl")}
          />
        </StyledSection>
        <StyledSection>
          <Toolbar variant="dense" disableGutters>
            <StyledTitle variant="subtitle2">{t("立ち絵・差分")}</StyledTitle>
            <IconButton
              onClick={() => {
                setFaceIndex(-1);
                onOpenDialog2();
              }}
              size="small"
              edge="end"
            >
              <AddIcon />
            </IconButton>
          </Toolbar>
          <Typography
            variant="caption"
            display="block"
            style={{ marginBottom: 16 }}
          >
            {t(
              "発言時にメッセージボックスに表示される画像を設定します。例えば、「＠笑顔」とラベルに設定した場合、発言時に「…@笑顔」のように付け加えることで差分を切り替えます。"
            )}
          </Typography>
          {faceFields.map((field, index) => (
            <StyledGroup key={field.id}>
              <IconButton
                size="small"
                onClick={() => {
                  setFaceIndex(index);
                  onOpenDialog2();
                }}
              >
                <Avatar src={toCDNUrl(field.iconUrl)} />
              </IconButton>
              <TextField
                variant="standard"
                label={t("ラベル")}
                margin="dense"
                fullWidth
                placeholder={t("@表情名やファンブル・クリティカルなど")}
                inputProps={register(`faces.${index}.label`)}
              />
              <IconButton onClick={() => removeFace(index)} size="small">
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={field.iconUrl === iconUrl}
                size="small"
                onClick={() =>
                  setValue("iconUrl", field.iconUrl, { shouldDirty: true })
                }
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            </StyledGroup>
          ))}
        </StyledSection>
        <StyledSection>
          <Toolbar variant="dense" disableGutters>
            <StyledTitle variant="subtitle2">{t("ステータス")}</StyledTitle>
            <IconButton
              onClick={() => removeStatus(statusFields.length - 1)}
              disabled={statusFields.length < 1}
              size="small"
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              onClick={() => appendStatus({ label: "", max: 0, value: 0 })}
              size="small"
              edge="end"
            >
              <AddIcon />
            </IconButton>
          </Toolbar>
          <Typography
            variant="caption"
            display="block"
            style={{ marginBottom: 16 }}
          >
            {t(
              "HPやMPなどのキャラクターに連動して変動するステータスを設定します。{ラベル名}のように発言するとチャットから現在値を参照することができます。"
            )}
          </Typography>
          {statusFields.map((field, index) => (
            <StyledGroup key={field.id}>
              <TextField
                variant="standard"
                label={t("ラベル")}
                margin="dense"
                fullWidth
                inputProps={register(`status.${index}.label`)}
              />
              <TextField
                variant="standard"
                type="number"
                label={t("現在値")}
                margin="dense"
                fullWidth
                inputProps={register(`status.${index}.value`, {
                  valueAsNumber: true,
                })}
              />
              <TextField
                variant="standard"
                type="number"
                label={t("最大値")}
                margin="dense"
                fullWidth
                inputProps={register(`status.${index}.max`, {
                  valueAsNumber: true,
                })}
              />
            </StyledGroup>
          ))}
        </StyledSection>
        <StyledSection>
          <Toolbar variant="dense" disableGutters>
            <StyledTitle variant="subtitle2">{t("パラメータ")}</StyledTitle>
            <IconButton
              onClick={() => removeParam(paramFields.length - 1)}
              disabled={paramFields.length < 1}
              size="small"
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              onClick={() => appendParam({ label: "", value: "0" })}
              size="small"
              edge="end"
            >
              <AddIcon />
            </IconButton>
          </Toolbar>
          <Typography
            variant="caption"
            display="block"
            style={{ marginBottom: 16 }}
          >
            {t(
              "キャラクターに対してめったに変動しないパラメータを設定します。{ラベル名}のように発言するとチャットから値を参照することができます。"
            )}
          </Typography>
          {paramFields.map((field, index) => (
            <StyledGroup key={field.id}>
              <TextField
                variant="standard"
                label={t("ラベル")}
                margin="dense"
                fullWidth
                inputProps={register(`params.${index}.label`)}
              />
              <TextField
                variant="standard"
                label={t("値")}
                margin="dense"
                fullWidth
                inputProps={register(`params.${index}.value`)}
              />
            </StyledGroup>
          ))}
        </StyledSection>
        <StyledSection>
          <Toolbar variant="dense" disableGutters>
            <StyledTitle variant="subtitle2">
              {t("チャットパレット")}
            </StyledTitle>
          </Toolbar>
          <Typography
            variant="caption"
            display="block"
            style={{ marginBottom: 16 }}
          >
            {t(
              "1d100 などのダイスコマンドやキャラクターに紐づくチャットコマンドを改行区切りで登録します。"
            )}
          </Typography>
          <TextField
            label={t("チャットパレット")}
            variant="filled"
            margin="dense"
            fullWidth
            multiline
            rows={6}
            inputProps={register("commands")}
          />
        </StyledSection>
        <List>
          <ListItem>
            <ListItemText
              primary={t("ステータスを非公開にする")}
              secondary={t("秘匿NPC・敵キャラクターなど")}
            />
            <ListItemSecondaryAction>
              <Controller
                name="secret"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Switch
                    onChange={onChange}
                    checked={value}
                    name={name}
                    inputRef={ref}
                  />
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t("発言時キャラクターを表示しない")}
              secondary={t("駒自体を立ち絵のように見せる場合")}
            />
            <ListItemSecondaryAction>
              <Controller
                name="invisible"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Switch
                    onChange={onChange}
                    checked={value}
                    name={name}
                    inputRef={ref}
                  />
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t("盤面キャラクター一覧に表示しない")}
              secondary={t("GM駒などステータス管理が必要ないもの")}
            />
            <ListItemSecondaryAction>
              <Controller
                name="hideStatus"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Switch
                    onChange={onChange}
                    checked={value}
                    name={name}
                    inputRef={ref}
                  />
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {/* <StyledSection>
          <Toolbar variant="dense" disableGutters>
            <StyledTitle variant="subtitle2">
              {t("ダイススキン")} <StyledCcfoliaProIcon />
            </StyledTitle>
          </Toolbar>
          <Typography variant="caption" display="block">
            {t(
              "このキャラクターでダイスロールをした際のダイスの種類を変更できます。"
            )}
          </Typography>
          <List>
            <ListItem button disabled={!isPro} onClick={onOpenDiceSkinSelect}>
              <ListItemAvatar>
                <StyledAvatar src={diceSkin?.thumbnailUrl} />
              </ListItemAvatar>
              <ListItemText primary={diceSkin?.name || "未設定"} />
              <ListItemSecondaryAction>
                {isPro && <EditIcon />}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <DiceSkinSelectDialog
            open={openDiceSkinSelect}
            target="character"
            diceSkinId={diceSkin?.id}
            onClose={onCloseDiceSkinSelect}
            onSelect={onSelectDiceSkin}
          />
        </StyledSection> */}
      </form>
      <FileListDialog {...dialog.dialogProps} onSelect={onSelectImage} />
      <FileListDialog
        {...dialog2.dialogProps}
        onSelect={(url) => {
          if (faceIndex >= 0) {
            updateFace(faceIndex, {
              label: faceFields[faceIndex].label,
              iconUrl: url,
            });
          } else {
            appendFace({ label: "", iconUrl: url });
          }
        }}
      />
    </>
  );
};

const StyledGroup = styled.div`
  display: flex;
  align-items: center;
  .MuiTextField-root {
    flex: 1;
  }
  > * + * {
    margin-left: 16px;
  }
  .MuiAvatar-root {
    background: rgba(0, 0, 0, 0.2);
    img {
      object-position: 50% 0%;
    }
    &.MuiAvatar-colorDefault {
      color: #363636;
    }
  }
`;

const StyledSection = styled.div`
  margin-top: 16px;
`;

const StyledTitle = styled(Typography)`
  flex-grow: 1;
`;

export default memo(CharacterForm);
