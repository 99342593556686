import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import { DraggableData } from "containers/DraggableItem";
import { memo, PropsWithChildren, useCallback } from "react";
import { useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import { fixSelectionOnDragStop } from "modules/drag";

const DraggableContext = ({ children }: PropsWithChildren) => {
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 2,
    },
  });

  const scale = useAppSelector((state) =>
    getAppState(state, "roomScreenScale")
  );
  const cellSize = useAppSelector((state) =>
    getAppState(state, "roomScreenCellSize")
  );

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, delta } = e;
      const data = active.data.current as DraggableData;
      const { initialPosition, onUpdate, alignWithGrid } = data;
      if (delta.x || delta.y) {
        const newX = initialPosition.x + (delta.x ?? 0) / scale / cellSize;
        const newY = initialPosition.y + (delta.y ?? 0) / scale / cellSize;
        const gridX = alignWithGrid ? Math.round(newX) : newX;
        const gridY = alignWithGrid ? Math.round(newY) : newY;

        const updatePosition = {
          ...initialPosition,
          x: gridX,
          y: gridY,
        };

        onUpdate(updatePosition);
      }
      fixSelectionOnDragStop();
    },
    [scale, cellSize]
  );

  return (
    <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
      {children}
    </DndContext>
  );
};

export default memo(DraggableContext);
