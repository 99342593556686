import styled from "styled-components";
import theme from "theme";
import { Link } from "react-router-dom";

import { Product } from "api";
import { Skeleton, useMediaQuery } from "@mui/material";

type GamesImageProps = {
  gamesContents: Pick<Product, "thumbnailUrl" | "id">[];
  reverse: boolean;
  loading: boolean;
};

const GamesCarousel = ({
  gamesContents,
  reverse,
  loading,
}: GamesImageProps) => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Carousel reverse={reverse}>
      <div className="carousel-wrap">
        <div className="carousel-inner">
          {loading ? (
            <Skeleton
              animation={false}
              width={matches ? 320 : 240}
              height={matches ? 180 : 135}
              sx={{ transform: " scale(1,1)" }}
            />
          ) : (
            gamesContents.map((game, index) => (
              <CarouselImage key={index}>
                <Link to={`/games/${game.id}`}>
                  <div className="image-item">
                    <img src={game.thumbnailUrl} alt="" />
                  </div>
                </Link>
              </CarouselImage>
            ))
          )}
        </div>
        <div className="carousel-inner">
          {loading ? (
            <Skeleton
              animation={false}
              width={matches ? 320 : 240}
              height={matches ? 180 : 135}
              sx={{ transform: " scale(1,1)" }}
            />
          ) : (
            gamesContents.map((game, index) => (
              <CarouselImage key={index}>
                <Link to={`/games/${game.id}`}>
                  <div className="image-item">
                    <img src={game.thumbnailUrl} alt="" />
                  </div>
                </Link>
              </CarouselImage>
            ))
          )}
        </div>
      </div>
    </Carousel>
  );
};

const Carousel = styled.div<{ reverse: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  .carousel-wrap {
    display: flex;
    animation: slide 180s linear infinite
      ${(props) => (props.reverse ? "reverse" : "")};
    &:hover {
      animation-play-state: paused;
    }
  }
  .carousel-inner {
    display: flex;
    gap: 0 24px;
    flex-basis: 50%;
    margin-right: ${(props) => (props.reverse ? "0" : "24px")};
    margin-left: ${(props) => (props.reverse ? "24px" : "0")};
  }

  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
`;

const CarouselImage = styled.div`
  width: 320px;
  &:hover {
    opacity: 0.7;
    transition: opacity 0.15s ease-in-out;
  }
  .image-item {
    overflow: hidden;
    border-radius: 16px;

    ${theme.breakpoints.down("md")} {
      border-radius: 12px;
    }
  }
  img {
    display: block;
    width: 100%;
  }

  ${theme.breakpoints.down("md")} {
    width: 240px;
  }
`;

export default GamesCarousel;
