import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import Room from "./Room";
import Loader from "./Loader";
import { subscribeUserState } from "stores/modules/app.user/operations";

type RoomContainerProps = {
  roomId: string;
};

const RoomContainer = ({ roomId }: RoomContainerProps) => {
  const dispatch = useAppDispatch();
  const uid = useAppSelector((state) => {
    return store.getAppState(state, "uid");
  });
  const settingsUid = useAppSelector((state) => state.entities.userSetting.uid);

  useEffect(() => {
    return dispatch(subscribeUserState());
  }, [dispatch]);

  useEffect(() => {
    if (settingsUid) {
      dispatch(store.updateUserAccessStatus());
    }
  }, [dispatch, settingsUid]);

  if (!uid) {
    return <Loader loading={true} />;
  }
  return <Room roomId={roomId} uid={uid} />;
};

export default memo(RoomContainer);
