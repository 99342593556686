import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpdateUserSetting, UserSetting, UserSettingRecord } from "./records";

const initializeState = (): UserSetting => {
  return {
    uid: null,
    skipInfomation: false,
    diceSkin: {},
    agreedTermsAt: null,
    markedGamesAt: null,
    lastAccessAt: null,
    isAnonymous: false,
  };
};

const slice = createSlice({
  name: "entities/userSetting",
  initialState: initializeState(),
  reducers: {
    update(
      state,
      action: PayloadAction<{ uid: string; setting: UpdateUserSetting }>
    ) {
      if (state.uid === action.payload.uid) {
        return { ...state, ...action.payload.setting };
      } else {
        return UserSettingRecord(action.payload.uid, action.payload.setting);
      }
    },
    set(_, action: PayloadAction<{ uid: string; setting: UpdateUserSetting }>) {
      return UserSettingRecord(action.payload.uid, action.payload.setting);
    },
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
