// NaNを考慮したclamp。
export const clamp = (
  x: number,
  option: { min: number; max: number }
): number => {
  if (Number.isNaN(x)) {
    return option.min;
  }

  if (x < option.min) {
    return option.min;
  } else if (x > option.max) {
    return option.max;
  } else {
    return x;
  }
};
