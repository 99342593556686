import { DndContext, DragEndEvent, useSensor, useSensors } from "@dnd-kit/core";
import { MouseSensor, TouchSensor } from "modules/draggableSensor";
import { memo, PropsWithChildren, useCallback } from "react";

const FloatItemDraggableContext = ({ children }: PropsWithChildren) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = useCallback((e: DragEndEvent) => {
    const { active, delta } = e;
    const onUpdate = active.data.current?.onUpdate;
    if (onUpdate && (delta.x || delta.y)) {
      onUpdate({ x: delta.x, y: delta.y });
    }
  }, []);

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} autoScroll={false}>
      {children}
    </DndContext>
  );
};

export default memo(FloatItemDraggableContext);
