import { DefaultThunk } from "stores";
import { UpdateScene } from "stores/modules/entities.room.scenes";
import {
  deleteScene,
  updateRoomScene,
} from "stores/modules/entities.room.scenes/operations";

export type UpdateSceneRecord = {
  kind: "update-scene";
  id: string;
  before: UpdateScene | null;
  after: UpdateScene | null;
};

export const undoUpdateScene =
  (history: UpdateSceneRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const before = history.before;

    if (!before) {
      // 変更前データがない場合は追加が行われたとして削除処理
      dispatch(deleteScene(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomScene(roomId, history.id, before, { notMerge: true }));
    return;
  };

export const redoUpdateScene =
  (history: UpdateSceneRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const after = history.after;

    if (!after) {
      // 変更後データがない場合は削除処理
      dispatch(deleteScene(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomScene(roomId, history.id, after, { notMerge: true }));
    return;
  };
