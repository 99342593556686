import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import theme from "theme";
import {
  Typography,
  DialogContent,
  Portal,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Resizable, ResizeCallback, ResizeStartCallback } from "re-resizable";
import { useDraggable } from "@dnd-kit/core";
import { flushSync } from "react-dom";

const dialogContentStyle = { padding: 0 };

const FloatWindow: React.FC<{
  open: boolean;
  title: string;
  onEdit?: () => any;
  onClose: () => any;
  onAdd?: () => any;
  children: ReactNode;
}> = (props) => {
  const { open, title, onEdit, onClose, onAdd, children } = props;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultWidth = 320;
  const defaultHeight = matches ? 400 : 280;
  const gridSize = 16;
  const x =
    ~~((window.innerWidth / 2 - defaultWidth / 2) / gridSize) * gridSize;
  const y =
    ~~((window.innerHeight / 2 + defaultHeight / 2) / gridSize) * gridSize;
  const dragStop = useRef(false);
  const [position, setPosition] = useState({
    x: x,
    y: -y,
  });
  const [resizePosition, setResizePosition] = useState({
    x: 0,
    y: 0,
  });
  const [resizeWidth, setResizeWidth] = useState(defaultWidth);
  const [resizeHeight, setResizeHeight] = useState(defaultHeight);
  const maxWidth = window.innerWidth - x;
  const maxHeight = window.innerHeight + y;
  const [maxSize, setMaxSize] = useState({
    width: maxWidth,
    height: maxHeight,
  });

  const getWindowPositionX = useCallback(
    (x: number) => {
      return Math.max(0, Math.min(window.innerWidth - resizeWidth, x));
    },
    [resizeWidth]
  );

  const getWindowPositionY = useCallback(
    (y: number) => {
      return Math.max(-window.innerHeight, Math.min(-resizeHeight, y));
    },
    [resizeHeight]
  );

  const handleUpdate = useCallback(
    (delta: { x: number; y: number }) => {
      setPosition({
        x: getWindowPositionX(position.x - resizePosition.x + delta.x),
        y: getWindowPositionY(position.y - resizePosition.y + delta.y),
      });
      setResizePosition({
        x: 0,
        y: 0,
      });
      setMaxSize({
        width: maxWidth,
        height: maxHeight,
      });
    },
    [
      position,
      resizePosition,
      maxWidth,
      maxHeight,
      getWindowPositionX,
      getWindowPositionY,
    ]
  );

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "float_window_" + title,
    data: {
      onUpdate: handleUpdate,
    },
    disabled: matches || dragStop.current,
  });
  const tmpPositionX = position.x - resizePosition.x + (transform?.x || 0);
  const tmpPositionY = position.y - resizePosition.y + (transform?.y || 0);
  const positionX = getWindowPositionX(tmpPositionX);
  const positionY = getWindowPositionY(tmpPositionY);

  const setWindowPosition = useCallback(() => {
    if (window.innerWidth > 320 && resizeWidth > window.innerWidth) {
      setResizeWidth(window.innerWidth);
    }
    if (window.innerHeight > 280 && resizeHeight > window.innerHeight) {
      setResizeHeight(window.innerHeight);
    }
    setPosition((draft) => {
      return {
        x: getWindowPositionX(draft.x),
        y: getWindowPositionY(draft.y),
      };
    });
  }, [
    setPosition,
    getWindowPositionX,
    getWindowPositionY,
    resizeWidth,
    resizeHeight,
    setResizeWidth,
    setResizeHeight,
  ]);

  useEffect(() => {
    if (open) {
      setWindowPosition();
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;

    window.addEventListener("resize", setWindowPosition);
    return () => {
      window.removeEventListener("resize", setWindowPosition);
    };
  }, [open, setWindowPosition]);

  const stopPropagation = useCallback(
    (e) => {
      e.stopPropagation();
      dragStop.current = true;
    },
    [dragStop]
  );

  const handleResizeStart: ResizeStartCallback = useCallback((_e, d, ref) => {
    const upperDirection = d.toUpperCase();
    const refRect = ref.getBoundingClientRect();
    const newMaxSize = {
      width: window.innerWidth - refRect.left,
      height: window.innerHeight - refRect.top,
    };
    if (upperDirection.includes("TOP")) {
      newMaxSize.height = refRect.height + refRect.top;
    }
    if (upperDirection.includes("LEFT")) {
      newMaxSize.width = refRect.width + refRect.left;
    }
    setMaxSize(newMaxSize);
  }, []);

  const handleResize: ResizeCallback = useCallback((_e, d, ref, delta) => {
    const newResizePosition = {
      x: 0,
      y: 0,
    };
    const upperDirection = d.toUpperCase();

    if (upperDirection.includes("TOP")) {
      newResizePosition.y = delta.height;
    }

    if (upperDirection.includes("LEFT")) {
      newResizePosition.x = delta.width;
    }
    if (upperDirection.includes("TOP") || upperDirection.includes("LEFT")) {
      flushSync(() => {
        setResizePosition(newResizePosition);
      });
    }
    flushSync(() => {
      setResizeWidth(ref.offsetWidth);
      setResizeHeight(ref.offsetHeight);
    });
  }, []);

  const handleResizeStop: ResizeCallback = useCallback(
    (_e, _d, _ref, _delta) => {
      setPosition({
        x: position.x - resizePosition.x,
        y: position.y - resizePosition.y,
      });
      setResizePosition({
        x: 0,
        y: 0,
      });
    },
    [position, resizePosition]
  );

  if (!open) return null;
  return (
    <Portal>
      <div
        ref={setNodeRef}
        style={{
          position: "absolute",
          transform: `translate3d(${positionX}px, ${positionY}px, 0)`,
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Resizable
          defaultSize={{
            width: defaultWidth,
            height: defaultHeight,
          }}
          size={{
            width: resizeWidth,
            height: resizeHeight,
          }}
          style={{ position: "absolute" }}
          grid={[gridSize, gridSize]}
          minWidth={320}
          minHeight={280}
          maxWidth={maxSize.width}
          maxHeight={maxSize.height}
          onResizeStart={handleResizeStart}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          enable={matches || dragStop.current ? false : undefined}
        >
          <Container square>
            <div
              {...attributes}
              {...listeners}
              style={{
                cursor: "move",
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            >
              <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense">
                  <Typography variant="subtitle2">{title}</Typography>
                  <DialogHeadActions data-no-dnd="true">
                    {onAdd ? (
                      <IconButton onClick={onAdd} size="small">
                        <AddIcon />
                      </IconButton>
                    ) : null}
                    {onEdit ? (
                      <IconButton onClick={onEdit} size="small">
                        <EditIcon />
                      </IconButton>
                    ) : null}
                    <IconButton onClick={onClose} size="small" edge="end">
                      <CloseIcon />
                    </IconButton>
                  </DialogHeadActions>
                </Toolbar>
              </AppBar>
            </div>
            <DialogContent
              style={dialogContentStyle}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
              onMouseUp={() => (dragStop.current = false)}
              onTouchEnd={() => (dragStop.current = false)}
            >
              <div>{children}</div>
            </DialogContent>
          </Container>
        </Resizable>
      </div>
    </Portal>
  );
};

const Container = styled(Paper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(44, 44, 44, 0.87);
  z-index: ${theme.zIndex.drawer + 1};
`;

const DialogHeadActions = styled.div`
  margin-left: auto;
  > .MuiIconButton-root + .MuiIconButton-root {
    margin-left: 4px;
  }
`;

export default FloatWindow;
