import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type GroupedEntityState<T> = {
  ids: string[];
  idsGroupBy: Record<string, string[]>;
  entities: Record<string, T>;
};

type CreateEntitySliceGroupByProps<T> = {
  name: string;
  selectGroup: (entity: T) => string;
};

export const createEntitySliceGroupBy = <T>({
  name,
  selectGroup,
}: CreateEntitySliceGroupByProps<T>) => {
  const initializeState = (): GroupedEntityState<T> => {
    return {
      ids: [],
      idsGroupBy: {},
      entities: {},
    };
  };

  const removeId = (ids: string[] | undefined, id: string) => {
    if (ids == null) {
      return;
    }

    const index = ids.indexOf(id);
    if (index >= 0) {
      ids.splice(index, 1);
    }
  };

  return createSlice({
    name,
    initialState: initializeState(),
    reducers: {
      init(state) {
        state.ids = [];
        state.idsGroupBy = {};
        state.entities = {};
      },
      add(state, action: PayloadAction<{ id: string; entity: T }>) {
        const { id, entity } = action.payload;
        if (id in state.entities) {
          return;
        }

        const group = selectGroup(entity);
        if (!(group in state.idsGroupBy)) {
          state.idsGroupBy[group] = [];
        }

        state.ids.push(id);
        state.idsGroupBy[group].push(id);
        (state.entities as Record<string, T>)[id] = entity;
      },
      update(state, action: PayloadAction<{ id: string; entity: T }>) {
        const { id, entity } = action.payload;
        const group = selectGroup(entity);

        if (!(group in state.idsGroupBy)) {
          state.idsGroupBy[group] = [];
        }

        if (!(id in state.entities)) {
          // add
          state.ids.push(id);
          state.idsGroupBy[group].push(id);
          (state.entities as Record<string, T>)[id] = entity;
          return;
        }

        // update
        const prevGroup = selectGroup(state.entities[id] as T);
        if (group !== prevGroup) {
          removeId(state.idsGroupBy[prevGroup], id);
          state.idsGroupBy[group].push(id);
        }

        (state.entities as Record<string, T>)[id] = entity;
      },
      remove(state, action: PayloadAction<string>) {
        const id = action.payload;
        const entity = state.entities[id];
        if (entity == null) {
          return;
        }

        removeId(state.ids, id);
        removeId(state.idsGroupBy[selectGroup(entity as T)], id);
        delete state.entities[id];
      },
      reorder(
        state,
        action: PayloadAction<{ startIndex: number; endIndex: number }>
      ) {
        const [target] = state.ids.splice(action.payload.startIndex, 1);
        state.ids.splice(action.payload.endIndex, 0, target);
      },
      sort(state, action: PayloadAction<string[]>) {
        if (state.ids.length === action.payload.length) {
          state.ids = action.payload;
        }
      },
      groupReorder(
        state,
        action: PayloadAction<{
          group: string;
          startIndex: number;
          endIndex: number;
        }>
      ) {
        const { group, startIndex, endIndex } = action.payload;
        if (!(group in state.idsGroupBy)) {
          return;
        }

        const [target] = state.idsGroupBy[group].splice(startIndex, 1);
        state.idsGroupBy[group].splice(endIndex, 0, target);
      },
      groupRemove(state, action: PayloadAction<string>) {
        const group = action.payload;
        if (!(group in state.idsGroupBy)) {
          return;
        }

        const removeIds = state.idsGroupBy[group];
        if (removeIds.length === 0) {
          return;
        }

        const removeIdsSet = new Set(removeIds);
        state.ids = state.ids.filter((id) => !removeIdsSet.has(id));
        state.idsGroupBy[group] = [];
        for (const id of removeIds) {
          delete state.entities[id];
        }
      },
    },
  });
};

type CreateEntitySliceProps = {
  name: string;
};

type EntityState<T> = {
  ids: string[];
  entities: Record<string, T>;
};

export const createEntitySlice = <T>({ name }: CreateEntitySliceProps) => {
  const initializeState = (): EntityState<T> => {
    return {
      ids: [],
      entities: {},
    };
  };

  const removeId = (ids: string[] | undefined, id: string) => {
    if (ids == null) {
      return;
    }

    const index = ids.indexOf(id);
    if (index >= 0) {
      ids.splice(index, 1);
    }
  };

  return createSlice({
    name,
    initialState: initializeState(),
    reducers: {
      init(state) {
        state.ids = [];
        state.entities = {};
      },
      add(state, action: PayloadAction<{ id: string; entity: T }>) {
        const { id, entity } = action.payload;
        if (id in state.entities) {
          return;
        }

        state.ids.push(id);
        (state.entities as Record<string, T>)[id] = entity;
      },
      update(state, action: PayloadAction<{ id: string; entity: T }>) {
        const { id, entity } = action.payload;

        if (!(id in state.entities)) {
          // add
          state.ids.push(id);
        }

        // update
        (state.entities as Record<string, T>)[id] = entity;
      },
      remove(state, action: PayloadAction<string>) {
        const id = action.payload;
        const entity = state.entities[id];
        if (entity == null) {
          return;
        }

        removeId(state.ids, id);
        delete state.entities[id];
      },
      reorder(
        state,
        action: PayloadAction<{ srcIndex: number; destIndex: number }>
      ) {
        const [target] = state.ids.splice(action.payload.srcIndex, 1);
        state.ids.splice(action.payload.destIndex, 0, target);
      },
      sort(state, action: PayloadAction<string[]>) {
        if (state.ids.length === action.payload.length) {
          state.ids = action.payload;
        }
      },
      updateOrder(state, action: PayloadAction<{ id: string; order: number }>) {
        if (action.payload.id in state.entities) {
          (state.entities[action.payload.id] as any).order =
            action.payload.order;
        }
      },
    },
  });
};
