import { createSelector } from "reselect";
import { getAppState } from "../app.state/selectors";
import { UserFile } from "./records";
import { DefaultRootState } from "stores";
import { sortBy } from "lodash-es";

const emptyUserFile: UserFile = {
  contentType: "",
  dir: "item",
  hash: "",
  name: "",
  owner: "",
  roomId: "",
  size: 0,
  uploaded: false,
  url: "",
  archived: false,
  createdAt: 0,
  updatedAt: 0,
};

export const getUserFiles = (state: DefaultRootState) => {
  return state.entities.userFiles.entities;
};

export const getUserFileById = (state: DefaultRootState, id: string) => {
  return state.entities.userFiles.entities[id] || emptyUserFile;
};

export const getUserFileIds = (state: DefaultRootState) => {
  return state.entities.userFiles.ids || [];
};

export const getUserFileIdsByDir = (state: DefaultRootState, dir: string) => {
  return state.entities.userFiles.idsGroupBy[dir] || [];
};

export const getUserFileIdsByCurrentDir = (state: DefaultRootState) => {
  const dir = getAppState(state, "openRoomImageSelectDir");
  return (dir && state.entities.userFiles.idsGroupBy[dir]) ?? [];
};

export const _getUserFileIdsByDir = (
  state: DefaultRootState,
  { dir }: { dir: string }
) => {
  return state.entities.userFiles.idsGroupBy[dir] || [];
};

export const getHashProps = (_: unknown, props: { hash: number | string }) => {
  return props.hash;
};

export const getUserFileByHash = createSelector(
  [getUserFiles, _getUserFileIdsByDir, getHashProps],
  (files, fileIds, hash) => {
    const id = fileIds.find((fileId) => {
      return files[fileId].hash === hash;
    });
    if (id === undefined) return null;
    return files[id];
  }
);

const getRoomId = (state: DefaultRootState) => getAppState(state, "roomId");
const getFilteredOption = (state: DefaultRootState) =>
  getAppState(state, "onlyFilteredFiles");

export const getRoomUserFileIds = createSelector(
  [getUserFiles, getUserFileIdsByDir, getRoomId],
  (files, fileIds, roomId) => {
    const filterdIds = fileIds.filter(
      (fileId) => files[fileId].roomId === roomId
    );
    return sortBy(filterdIds, [
      (id) => files[id].updatedAt,
      (id) => id,
    ]).reverse();
  }
);

export const getCurrentUserFileIds = createSelector(
  [getUserFiles, getUserFileIdsByCurrentDir, getFilteredOption, getRoomId],
  (files, fileIds, filtered, roomId) => {
    if (filtered) {
      return fileIds.filter((fileId) => files[fileId].roomId === roomId);
    } else {
      return fileIds;
    }
  }
);

export const getSortedUserFileIdsByDir = createSelector(
  [getUserFiles, getUserFileIdsByDir],
  (files, fileIds) => {
    return sortBy(fileIds, [(id) => files[id].updatedAt, (id) => id]).reverse();
  }
);
