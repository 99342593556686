import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import {
  getRoomSceneById,
  getRoomSceneIds,
  getRoomSceneOrderedIds,
} from "./selectors";
import { getCurrentRoomField, getRoomById } from "../entities.rooms/selectors";
import { updateRoom } from "../entities.rooms/operations";
import { Marker, UpdateMarker } from "../entities.rooms/index";
import { UpdateScene } from "./";
import { Scene, SceneRecord } from "./records";
import { DefaultRootState, DefaultThunk } from "stores";
import {
  addMessage,
  DEFAULT_TEXT_COLOR_CODE,
} from "../entities.room.messages/operations";
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  DocumentData,
  FirestoreDataConverter,
  orderBy,
  query,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { addUndo } from "../entities.room.histories/slice";
import { getAppState } from "../app.state/selectors";
import {
  calcOrderByInsert,
  forceAlignOrders,
  reorderEntities,
  undoRedoReorderEntities,
} from "../firestoreModuleUtils/ordaring";

const sceneConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(scene: UpdateScene): DocumentData {
    return scene;
  },
  fromFirestore(snapshot, options): Scene {
    const data = snapshot.data(options)!;
    return SceneRecord(data);
  },
};

export const scenesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "scenes").withConverter(sceneConverter);

export const subscribeRoomScenes = createSubscribeCollection(
  actions,
  (roomId: string) => query(scenesRef(roomId), orderBy("order"))
);

export const addScene =
  (roomId: string, sceneId: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const room = getRoomById(state, roomId);

    const sceneIds = getRoomSceneOrderedIds(state);
    const targetSceneIndex = sceneIds.indexOf(sceneId);
    const insertTo =
      targetSceneIndex >= 0 ? targetSceneIndex + 1 : sceneIds.length;
    const order = calcOrderByInsert(
      sceneIds,
      state.entities.roomScenes.entities,
      insertTo
    );

    const data = {
      ...SceneRecord({
        backgroundUrl: room.backgroundUrl,
        foregroundUrl: room.foregroundUrl,
        fieldObjectFit: room.fieldObjectFit,
        fieldWidth: room.fieldWidth,
        fieldHeight: room.fieldHeight,
        displayGrid: room.displayGrid,
        gridSize: room.gridSize,
        mediaName: room.mediaName,
        mediaUrl: room.mediaUrl,
        mediaRepeat: room.mediaRepeat ?? true,
        mediaType: room.mediaType,
        mediaVolume: room.mediaVolume,
        soundName: room.soundName,
        soundUrl: room.soundUrl,
        soundRepeat: room.soundRepeat || false,
        soundVolume: room.soundVolume,
        markers: room.markers,
        order: order ?? insertTo,
      }),
      updatedAt: Date.now(),
      createdAt: Date.now(),
    };
    const doc = await addDoc(scenesRef(roomId), data);
    dispatch(
      addUndo({
        kind: "update-scene",
        id: doc.id,
        before: null,
        after: data,
      })
    );

    if (order === null) {
      const insertedIds = sceneIds.splice(insertTo, 0, doc.id);
      await forceAlignOrders(insertedIds, roomId, scenesRef);
    }
  };

type UpdateSceneOption = {
  notMerge: boolean;
};

export const updateRoomScene =
  (
    roomId: string,
    sceneId: string,
    scene: UpdateScene,
    option?: UpdateSceneOption
  ) =>
  () => {
    const isMerge = !option?.notMerge;
    return setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        ...scene,
        updatedAt: Date.now(),
      },
      { merge: isMerge }
    );
  };

export const reorderScenes = reorderEntities({
  selectOrderdIds: getRoomSceneOrderedIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomScenes.entities,
  actionReorder: actions.reorder,
  actionUpdateOrder: actions.updateOrder,
  collectionRef: scenesRef,
  type: "scene",
});

export const undoRedoReorderScenes = undoRedoReorderEntities({
  selectOrderdIds: getRoomSceneOrderedIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomScenes.entities,
  actionReorder: actions.reorder,
  actionUpdateOrder: actions.updateOrder,
  collectionRef: scenesRef,
});

export const updateRoomSceneMarker =
  (roomId: string, sceneId: string, markerId: string, data: UpdateMarker) =>
  () => {
    return setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        markers: {
          [markerId]: {
            ...data,
          },
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const upsertRoomSceneMarker =
  (roomId: string, sceneId: string, markers: Record<string, Marker>) => () => {
    return updateDoc(doc(scenesRef(roomId), sceneId), {
      markers,
      updatedAt: Date.now(),
    });
  };

export const addRoomSceneMarker =
  (roomId: string, sceneId: string, data: UpdateMarker) => async () => {
    const markerId = Date.now().toString(16);
    await setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        markers: {
          [markerId]: {
            x: data.x || -1,
            y: data.y || -1,
            z: 1,
            width: data.width || 2,
            height: data.height || 2,
            locked: false,
            imageUrl: data.imageUrl || null,
            text: "",
          },
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
    return {
      id: markerId,
    };
  };

export const deleteRoomSceneMarker =
  (roomId: string, sceneId: string, markerId: string) => () => {
    return setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        markers: {
          [markerId]: deleteField(),
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateSceneFromRoom =
  (roomId: string, sceneId: string): DefaultThunk =>
  (dispatch, getState) => {
    const room = getRoomById(getState(), roomId);
    const beforeScene = getRoomSceneById(getState(), sceneId);
    const data = {
      backgroundUrl: room.backgroundUrl || null,
      foregroundUrl: room.foregroundUrl || null,
      fieldObjectFit: room.fieldObjectFit,
      fieldWidth: room.fieldWidth,
      fieldHeight: room.fieldHeight,
      displayGrid: room.displayGrid,
      gridSize: room.gridSize,
      mediaName: room.mediaName || "",
      mediaUrl: room.mediaUrl || null,
      mediaRepeat: "mediaRepeat" in room ? room.mediaRepeat : true,
      mediaType: room.mediaType || "",
      mediaVolume: room.mediaVolume || 0.5,
      soundName: room.soundName || "",
      soundUrl: room.soundUrl || null,
      soundRepeat: room.soundRepeat || false,
      soundVolume: room.soundVolume || 0.5,
      markers: room.markers || {},
      updatedAt: Date.now(),
    };
    updateDoc(doc(scenesRef(roomId), sceneId), data);
    dispatch(
      addUndo({
        kind: "update-scene",
        id: sceneId,
        before: beforeScene,
        after: data,
      })
    );
    return;
  };

export const deleteScene = (roomId: string, sceneId: string) => () => {
  return deleteDoc(doc(scenesRef(roomId), sceneId));
};

export const importRoomScenes =
  (scenesData: { [id: string]: UpdateScene }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    const sceneIds = Object.keys(scenesData);
    const batch = writeBatch(db);
    const ref = scenesRef(roomId);
    sceneIds.forEach((sceneId) => {
      batch.set(doc(ref, sceneId), SceneRecord(scenesData[sceneId]));
    });
    return batch.commit();
  };

export type ApplySceneOptions = {
  withoutBgm?: boolean;
};

export const applyScene =
  (
    roomId: string,
    sceneId: string,
    options?: ApplySceneOptions
  ): DefaultThunk =>
  (dispatch, getState) => {
    if (!roomId) {
      return;
    }

    const scene = getRoomSceneById(getState(), sceneId);
    if (options?.withoutBgm) {
      dispatch(
        updateRoom(roomId, {
          fieldWidth: scene.fieldWidth,
          fieldHeight: scene.fieldHeight,
          backgroundUrl: scene.backgroundUrl,
          foregroundUrl: scene.foregroundUrl,
          displayGrid: scene.displayGrid,
          gridSize: scene.gridSize,
          fieldObjectFit: scene.fieldObjectFit,
          markers: scene.markers || {}, // todo: migration
        })
      );
    } else {
      dispatch(
        updateRoom(roomId, {
          fieldWidth: scene.fieldWidth,
          fieldHeight: scene.fieldHeight,
          backgroundUrl: scene.backgroundUrl,
          foregroundUrl: scene.foregroundUrl,
          displayGrid: scene.displayGrid,
          gridSize: scene.gridSize,
          fieldObjectFit: scene.fieldObjectFit,
          mediaName: scene.mediaName,
          mediaType: scene.mediaType,
          mediaUrl: scene.mediaUrl,
          mediaVolume: scene.mediaVolume || 0.5, // todo: migration
          mediaRepeat: scene.mediaRepeat,
          soundName: scene.soundName,
          soundUrl: scene.soundUrl,
          soundVolume: scene.soundVolume || 0.5, // todo: migration
          soundRepeat: scene.soundRepeat,
          markers: scene.markers || {}, // todo: migration
        })
      );
    }
    if (scene.text) {
      dispatch(
        addMessage(
          roomId,
          null,
          {
            name: scene.name,
            text: scene.text,
            color: DEFAULT_TEXT_COLOR_CODE,
            channel: "main",
          },
          false
        )
      );
    }
  };

// secret function
const loadImage = (url: string) => {
  const image = new Image();
  image.src = url;
};
const loadAudio = (url: string) => {
  const req = new XMLHttpRequest();
  req.open("GET", url);
  req.send();
};

export const preloadSceneResources = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const sceneIds = getRoomSceneIds(state);
  sceneIds.forEach((sceneId) => {
    const scene = getRoomSceneById(state, sceneId);
    if (scene.backgroundUrl) loadImage(scene.backgroundUrl);
    if (scene.foregroundUrl) loadImage(scene.foregroundUrl);
    if (scene.mediaUrl) loadAudio(scene.mediaUrl);
    if (scene.soundUrl) loadAudio(scene.soundUrl);
  });
  window.alert("LAUNCH SCENE RESOURCES PRELOADER");
};

export const addUndoSceneChange =
  (sceneId: string, withoutBgm?: boolean): DefaultThunk =>
  (dispatch, getState) => {
    const field = getCurrentRoomField(getState());
    const selectedSceneId = getAppState(getState(), "selectedSceneId");
    const scene = getRoomSceneById(getState(), sceneId);
    const beforeScene: UpdateScene = {
      ...field,
      _id: selectedSceneId,
    };
    const afterScene = {
      _id: sceneId,
      fieldWidth: scene.fieldWidth,
      fieldHeight: scene.fieldHeight,
      backgroundUrl: scene.backgroundUrl,
      foregroundUrl: scene.foregroundUrl,
      displayGrid: scene.displayGrid,
      gridSize: scene.gridSize,
      fieldObjectFit: scene.fieldObjectFit,
      mediaName: scene.mediaName,
      mediaType: scene.mediaType,
      mediaUrl: scene.mediaUrl,
      mediaVolume: scene.mediaVolume,
      mediaRepeat: scene.mediaRepeat,
      soundName: scene.soundName,
      soundUrl: scene.soundUrl,
      soundVolume: scene.soundVolume,
      soundRepeat: scene.soundRepeat,
      markers: scene.markers || {},
    };

    dispatch(
      addUndo({
        kind: "apply-scene",
        before: beforeScene,
        after: afterScene,
        withoutBgm: withoutBgm ?? false,
      })
    );
  };

export const applySceneByUpdateScene =
  (roomId: string, scene: UpdateScene, withoutBgm?: boolean): DefaultThunk =>
  (dispatch, _) => {
    if (!roomId) {
      return;
    }
    let bgmSettings = {};
    if (!withoutBgm) {
      bgmSettings = {
        mediaName: scene.mediaName,
        mediaType: scene.mediaType,
        mediaUrl: scene.mediaUrl,
        mediaVolume: scene.mediaVolume || 0.5,
        mediaRepeat: scene.mediaRepeat,
        soundName: scene.soundName,
        soundUrl: scene.soundUrl,
        soundVolume: scene.soundVolume || 0.5,
        soundRepeat: scene.soundRepeat,
      };
    }

    dispatch(
      updateRoom(roomId, {
        ...bgmSettings,
        fieldWidth: scene.fieldWidth,
        fieldHeight: scene.fieldHeight,
        backgroundUrl: scene.backgroundUrl,
        foregroundUrl: scene.foregroundUrl,
        displayGrid: scene.displayGrid,
        gridSize: scene.gridSize,
        fieldObjectFit: scene.fieldObjectFit,
        markers: scene.markers || {},
      })
    );
  };
