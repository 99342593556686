import styled from "styled-components";
import theme from "theme";

import { Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import TranslationButton from "containers/TranslationButton";

const Footer = () => {
  const [t] = useTranslation();
  const year = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterInner>
        <FooterMenuList>
          <FooterMenuListItem>
            <Typography variant="caption" color="textSecondary">
              <Link
                href="/termsOfService.html"
                target="_blank"
                underline="hover"
              >
                {t("利用規約")}
              </Link>
            </Typography>
          </FooterMenuListItem>
          <FooterMenuListItem>
            <Typography variant="caption" color="textSecondary">
              <Link
                href="/termsOfSoundLibrary.html"
                target="_blank"
                underline="hover"
              >
                {t("音源利用規約")}
              </Link>
            </Typography>
          </FooterMenuListItem>
          <FooterMenuListItem>
            <Typography variant="caption" color="textSecondary">
              <Link
                href="/termsForSales.html"
                target="_blank"
                underline="hover"
              >
                {t("特定商取引法に基づく表示")}
              </Link>
            </Typography>
          </FooterMenuListItem>
          <FooterMenuListItem>
            <Typography variant="caption" color="textSecondary">
              <Link
                href="/privacyPolicy.html"
                target="_blank"
                underline="hover"
              >
                {t("プライバシーポリシー")}
              </Link>
            </Typography>
          </FooterMenuListItem>
          <FooterMenuListItem>
            <Typography variant="caption" color="textSecondary">
              <Link
                href="https://docs.ccfolia.com/information/support"
                target="_blank"
                underline="hover"
              >
                {t("お問い合わせ")}
              </Link>
            </Typography>
          </FooterMenuListItem>
        </FooterMenuList>
        <FooterLanguageButton>
          <TranslationButton buttonStyle="BorderButton" />
        </FooterLanguageButton>
        <FooterLogo>
          <img src="images/logo-white.svg" alt="CCFOLIAのロゴ" />
        </FooterLogo>
        <FooterCopy>&copy; {year} ccfolia.com</FooterCopy>
      </FooterInner>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  margin: 200px auto 0;
  padding: 56px 0;
  background-color: rgb(22, 22, 22);
  ${theme.breakpoints.down("sm")} {
    margin-top: 120px;
  }
`;

const FooterInner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  grid-template-areas: "menuList language" "logo blank" "copy blank";
  justify-content: space-between;
  margin: 0 auto;
  width: calc(1152 / 1400 * 100%);
  max-width: 1152px;
  ${theme.breakpoints.down("md")} {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);
    grid-template-areas: "language" "menuList" "logo" "copy";
    width: calc(342 / 390 * 100%);
  }
`;

const FooterMenuList = styled.ul`
  display: flex;
  gap: 0 16px;
  grid-area: menuList;
  list-style: none;
  font-size: 14px;
  line-height: 1;
  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    gap: 12px 0;
    margin-top: 40px;
  }
`;

const FooterMenuListItem = styled.li``;
const FooterLanguageButton = styled.div`
  grid-area: language;
`;
const FooterLogo = styled.div`
  grid-area: logo;
  margin-top: 40px;
  width: 180px;
`;

const FooterCopy = styled.div`
  grid-area: copy;
  margin-top: 10px;
  color: #acacac;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
`;

export default memo(Footer);
